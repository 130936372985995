import { Button, Table, Toast } from "@douyinfe/semi-ui";
import { useEffect, useState } from "react";
import { IconDownload, IconEyeOpened, IconDelete } from "@douyinfe/semi-icons";
import requests from "../../../../utils/request";
import { download_file, del_file, get_file_url } from "../../../../utils/file";
import { generateRandomString } from "../../../../utils/user_friendly";
import { get_data } from "../../../../utils/data_ctl";

export default function FileList({
  item_id,
  type,
  show_download = true,
  show_preview = true,
  show_del = false,
}) {
  const [tb_data, setTbData] = useState([]);
  const [load, set_load] = useState(false);
  const columns = [
    {
      title: "文件名称",
      dataIndex: "name",
    },
    {
      title: "操作",
      dataIndex: "operate",
      render: (text, record) => (
        <Operation
          text={text}
          record={record}
          dataSource={tb_data}
          setData={setTbData}
          show_download={show_download}
          show_preview={show_preview}
          show_del={show_del}
        />
      ),
    },
  ];

  useEffect(() => {
    requests
      .get("/file/file_list", {
        params: {
          item_id,
          type,
        },
      })
      .then((res) => {
        setTbData(res.data);
      })
      .finally(() => {
        set_load(false);
      });
  }, [item_id, type]);
  return (
    <>
      <Table
        columns={columns}
        dataSource={tb_data}
        rowKey="file_id"
        pagination={false}
        bordered={true}
        loading={load}
      />
    </>
  );
}

const net_type = get_data("net_type");
const preview_host =
  net_type === "WAN"
    ? process.env.REACT_APP_FILE_PREVIEW_URL
    : process.env.REACT_APP_LAN_FILE_PREVIEW_URL;

function Operation({
  text,
  record,
  dataSource,
  setData,
  show_download,
  show_preview,
  show_del,
}) {
  const removeRecord = (key) => {
    let newDataSource = [...dataSource];
    if (key != null) {
      let idx = newDataSource.findIndex((data) => data.file_id === key);
      if (idx > -1) {
        newDataSource.splice(idx, 1);
        setData(newDataSource);
      }
    }
  };
  const handleDel = () => {
    del_file(record.path, record.file_id, (res) => {
      Toast.success("删除成功");
      removeRecord(record.file_id);
    });
  };
  const handlePreview = async () => {
    const suffix = record.name.substring(record.name.lastIndexOf("."));

    const url =
      (await get_file_url(record)) +
      `&fullfilename=${generateRandomString(10)}${suffix}`;
    // window.open(
    //   process.env.REACT_APP_FILE_PREVIEW_URL +
    //     "/onlinePreview?url=" +
    //     btoa(encodeURIComponent(url))
    // );
    window.location.href =
      preview_host + "/onlinePreview?url=" + btoa(encodeURIComponent(url));
  };
  return (
    <>
      {show_download ? (
        <Button
          icon={<IconDownload />}
          size="small"
          theme="solid"
          style={{ marginRight: 10 }}
          onClick={() => {
            download_file(record);
          }}
        >
          下载
        </Button>
      ) : null}
      {show_preview ? (
        <Button
          icon={<IconEyeOpened />}
          size="small"
          theme="solid"
          style={{ marginRight: 10 }}
          onClick={handlePreview}
        >
          预览
        </Button>
      ) : null}

      {show_del ? (
        <Button
          icon={<IconDelete />}
          size="small"
          theme="solid"
          type="danger"
          onClick={handleDel}
        >
          删除
        </Button>
      ) : null}
    </>
  );
}
