

// 保存数据的类
class DataObj {
    data
    start_t
    max_age
    constructor(data, start_t, max_age=null) {
        this.data = data
        this.start_t = start_t
        this.max_age = max_age
    }
    get_d() {
        const now = new Date().getTime()
        if(this.max_age) {
            if(now - this.start_t >= this.max_age) {
                return null
            }
        }
        return this.data
    }

}



export function set_data(key, data, use_session = false, max_age = null) {
    const now = new Date().getTime()
    if (!use_session) {
        if (!max_age) {
            data = new DataObj(data, now)
            
        } else {
            data = new DataObj(data, now, max_age)
        }
        localStorage.setItem(key, serializeInstance(data))
    } else {
        sessionStorage.setItem(key, serializeInstance(data))
    }
    return true
}

export function get_data(key, use_session = false) {
    let data = null
    if (!use_session) {
        data = localStorage.getItem(key)
        if(!data) return null
        data = deserializeInstance(data, use_session)
        const d = data.get_d()
        data = d
    }
    else data = deserializeInstance(sessionStorage.getItem(key), use_session)
    return data
}

export function remove_data(key, use_session = false) {
    if (!use_session) {
        localStorage.removeItem(key)
    } else {
        sessionStorage.removeItem(key)
    }
    return true
}

function serializeInstance(instance) {
    return JSON.stringify(instance);
}

function deserializeInstance(jsonString, use_session = false) {
    const obj = JSON.parse(jsonString);
    if(!use_session) {
        return new DataObj(obj.data, obj.start_t, obj.max_age);
    }
    return obj
    
    
}