import { Button } from "@douyinfe/semi-ui";
import { IconLock } from "@douyinfe/semi-icons";
import { closeWindow } from "../../../../../utils/dd_api";

export default function ShouwenYueshiForbidden() {

  const onClose = () => {
    closeWindow()
  }
  return (
    <>
      <div style={{textAlign: 'center', marginTop: '30%'}}>
        <IconLock style={{fontSize: 50, color: "var(--semi-color-danger)"}} />
        <h2>访问被拒绝</h2>
        <Button onClick={onClose}>关闭</Button>
      </div>
    </>
  );
}
