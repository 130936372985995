import { React, useEffect, useState, useRef } from "react";
import {
  Tabs,
  TabPane,
  Spin,
  Input,
  Button,
  Card,
  Space,
  Tag,
  Typography,
  BackTop,
} from "@douyinfe/semi-ui";
import { IconArrowUp } from "@douyinfe/semi-icons";
// import "./AppMain.modules.css";
import requests from "../../../../../utils/request_noloading";
import moment from "moment";
import { isBlank } from "../../../../../utils/user_friendly";
import { change_route } from "../../../../../router";
import InfiniteScroll from "../../../../../component/infinite_scroll";

export default function YuewenAppMain() {
  const [all_data_count, set_all_data_count] = useState("加载中...");
  const [unread_data_count, set_unread_data_count] = useState("加载中...");
  const [readed_data_count, set_readed_data_count] = useState("加载中...");

  const [keyword, set_keyword] = useState("");
  const [fetch_flag, set_fetch_flag] = useState(false);

  const handle_search = () => {
    set_fetch_flag(!fetch_flag);
  };

  return (
    <>
      <Input
        placeholder="输入关键字搜索"
        size="large"
        showClear
        onChange={(val) => set_keyword(val)}
        style={{ float: "left", width: "75%" }}
      />
      <Button
        theme="solid"
        size="large"
        onClick={handle_search}
        style={{ float: "left", width: "20%", marginLeft: "5%" }}
      >
        搜 索
      </Button>
      <br />
      <br />
      <br />
      <Tabs type="button">
        <TabPane tab={`全部(${all_data_count})`} itemKey="1">
          <YuewenInfoList
            raw_data={{ is_look: null }}
            set_count={set_all_data_count}
            keyword={keyword}
            fetch_flag={fetch_flag}
          />
        </TabPane>
        <TabPane tab={`未处理(${unread_data_count})`} itemKey="2">
          <YuewenInfoList
            raw_data={{ is_look: false }}
            set_count={set_unread_data_count}
            keyword={keyword}
            fetch_flag={fetch_flag}
          />
        </TabPane>
        <TabPane tab={`已处理(${readed_data_count})`} itemKey="3">
          <YuewenInfoList
            raw_data={{ is_look: true }}
            set_count={set_readed_data_count}
            keyword={keyword}
            fetch_flag={fetch_flag}
          />
        </TabPane>
      </Tabs>
      <BackTop
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 35,
          width: 35,
          borderRadius: "100%",
          backgroundColor: "#0077fa",
          color: "#fff",
          bottom: 30,
          right: 10,
        }}
      >
        <IconArrowUp />
      </BackTop>
    </>
  );
}

function YuewenInfoList({ raw_data, set_count, keyword, fetch_flag }) {
  const [datasource, set_datasource] = useState([]);
  const [currentPage, setCurPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetch_data = (data_cb, curPage = currentPage, ds = datasource) => {
    setLoading(true);
    requests
      .post("/office/search_yuewen_full_info", {
        ...raw_data,
        currentPage: curPage,
        pageSize: 10,
        keyword,
        is_send: true,
      })
      .then((res) => {
        if (res.data.result && res.data.result.length > 0) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
        data_cb(res.data, ds);
        setCurPage(curPage + 1);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const load_data = (data, ds) => {
    if (data.result) {
      const new_data = ds.concat(data.result);
      set_datasource(new_data);
      set_count(data.total);
    }
  };

  const load_more = () => {
    fetch_data(load_data);
  };

  useEffect(() => {
    fetch_data(load_data, 1, []);
  }, [fetch_flag]);
  return (
    <>
      <InfiniteScroll hasMore={hasMore} loadMore={load_more} loading={loading}>
        {datasource.map((data, idx) => {
          return <YuwenDetail key={idx} data={data} />;
        })}
      </InfiniteScroll>
    </>
  );
}

function YuwenDetail({ data }) {
  const { Title } = Typography;
  const handle_to_detail = (data) => {
    change_route(
      `/office/shouwen_yueshi?sw_id=${data.sw_id}&order=${data.order_num}`
    );
  };

  const get_sw_type_color = (sw_type, color = "blue") => {
    switch (sw_type) {
      case "传阅":
        return "pink";
      case "会议及培训":
        return "green";
      default:
        return color;
    }
  };
  return (
    <>
      <center>
        {data.look_time ? (
          <Tag color="grey">
            {moment(data.look_time).format("YYYY-MM-DD HH:mm")}
          </Tag>
        ) : null}
      </center>
      <Card style={{ boxShadow: "box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1)" }}>
        <div style={containerStyle}>
          {data.is_look ? (
            <>
              <Tag color="blue" style={tagStyle} size="large">
                已处理
              </Tag>
              <Tag color="blue" style={tagStyle} size="large">
                {data.bz}
              </Tag>
              <Tag
                color={get_sw_type_color(data.sw_type, "blue")}
                style={tagStyle}
                size="large"
              >
                {data.sw_type}
              </Tag>
            </>
          ) : (
            <>
              <Tag color="orange" style={tagStyle} size="large">
                未处理
              </Tag>
              <Tag color="orange" style={tagStyle} size="large">
                {data.bz}
              </Tag>
              <Tag
                color={get_sw_type_color(data.sw_type, "orange")}
                style={tagStyle}
                size="large"
              >
                {data.sw_type}
              </Tag>
            </>
          )}

          <Title
            style={titleStyle}
            heading={5}
            type="warning"
            onClick={() => handle_to_detail(data)}
            link
            underline
          >
            {!isBlank(data.ywbt)
              ? data.ywbt
              : !isBlank(data.hymc)
              ? data.hymc
              : "[无标题,点击查看详情]"}
          </Title>
        </div>
      </Card>
      <br />
    </>
  );
}

const containerStyle = {
  display: "flex",
  alignItems: "center", // 垂直居中对齐
  flexWrap: "wrap", // 允许内容换行
};

const tagStyle = {
  marginRight: "8px", // 标签与标题之间的间距
  borderRadius: "4px", // 圆角
  padding: "4px 8px", // 内边距
};

const titleStyle = {
  whiteSpace: "normal", // 允许文字换行
  wordBreak: "break-all", // 强制换行
  flex: 1, // 让标题占据剩余空间
};
