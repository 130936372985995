import {
  Modal,
  Table,
  Toast,
  Timeline,
  Badge,
  Avatar,
  Space,
  Card,
} from "@douyinfe/semi-ui";
import { useEffect, useState } from "react";
import YuewenLiuchengShow from "../../qianduan/pages/yuewen_liucheng_show";

export default function ShouwenLiuChengShow({ visible, setvisible, sw_id }) {
  const Cancel = () => {
    setvisible(false);
  };
  return (
    <>
      <Modal
        title="阅示进度"
        visible={visible}
        onOk={Cancel}
        onCancel={Cancel}
        closeOnEsc={true}
        footerFill={true}
      >
        <YuewenLiuchengShow sw_id={sw_id} />
      </Modal>
    </>
  );
}
