import requests from "./utils/request_noloading";
import { get_data, set_data } from "./utils/data_ctl";

// 加载用户信息
const load_user_info = (onSuccess = () => {}, onFail = () => {}) => {
  const token = get_data("token");
  // 用户没登录
  if (!token) {
    onFail();
    return;
  }

  // 获取用户菜单
  const get_user_menu = async () => {
    const res = await requests.get("/skyy/admin/get_user_menus");
    set_data("user_menus", res.data, true);
    const permission_paths = {};
    res.data.forEach((val) => {
      if (val.path != null) {
        permission_paths[val.path] = 1;
      }
    });
    // 数据量太大，只能使用session
    set_data("permission_paths", permission_paths, true);
  };

  // 获取用户信息
  const get_user_info = async () => {
    const res = await requests.get("/skyy/user/get_self_info")
    set_data("user", res.data)
  };

  Promise.all([get_user_menu(), get_user_info()]).then(res => {
    onSuccess()
  }).catch((err => {
    onFail()
  }))
};

export { load_user_info };
