import { change_route } from "../router"
import { remove_data } from "./data_ctl"

// 携带当前url跳转回登录页
export function to_login(redirect=true, custom_params=null) {
    // 如果已经在登录页则不跳转
    if(["/login", "/auth/uni_qr_login"].includes(window.location.pathname)) return;
    if(custom_params !== null) {
        change_route(`/login${custom_params}`)
    }
    else if(redirect) {
        change_route('/login?redirect=' + encodeURIComponent(window.location.pathname + window.location.search) + "&login_sys=local")
        // window.location.href = '/login?redirect=' + encodeURIComponent(window.location.pathname + window.location.search)
    } else {
        change_route('/login?login_sys=local')
        // window.location.href = '/login'
    }
}

// 退出登录
export function logout() {
    // window.location.href = '/login?notautologin=true'
    change_route("/login?notautologin=true")
}

// 将url的查询参数转为json
export function convertUrlParamsToJson(urlParams) {
    urlParams = new URLSearchParams(urlParams)
    var jsonParams = {};
    for (var pair of urlParams.entries()) {
        jsonParams[pair[0]] = pair[1];
    }
    return jsonParams;
}