import { DatePicker, Layout, Button, Table } from "@douyinfe/semi-ui";
import MyButton from "../../../pages/MyButton";
import React, { useState } from "react";
import exportToExcel from "../../../utils/export_exc";
import request from "../../../utils/request";
import "../../../css/a.css";

//列名
const columns = [
  { title: "科室", dataIndex: "dept", width: 180 },
  { title: "病重人数", dataIndex: "bzrs" },
  { title: "病危人数", dataIndex: "bwrs" },
  { title: "药物使用错误人数", dataIndex: "ywsycwrs" },
  { title: "高危药物外渗人数", dataIndex: "gwywwsrs" },
  { title: "输液反应人数", dataIndex: "syfyrs" },
  { title: "输血反应人数", dataIndex: "sxfyrs" },
  { title: "非计划性拔管人数", dataIndex: "fjhxbgrs" },
  { title: "留置导管人数", dataIndex: "lzdgrs" },
  { title: "压疮高危风险新增例数", dataIndex: "ycgwfxxzls" },
  { title: "Ⅱ期及以上压疮发生人数（院内）", dataIndex: "eqysycfsrsyn" },
  { title: "Ⅱ期及以上压疮发生人数（院外）", dataIndex: "eqysycfsrsyw" },
  { title: "跌倒高危风险新增例数", dataIndex: "ddgwfxxzls" },
  { title: "跌倒人数（高危人群）", dataIndex: "ddrsgw" },
  { title: "跌倒人数（非高危人群）", dataIndex: "ddrsfgw" },
  { title: "误吸人数", dataIndex: "wxrs" },
  { title: "导管相关血流感染新增例数", dataIndex: "dgxgxlgrxzls" },
  { title: "导管相关性尿路感染新增例数", dataIndex: "dgxgxnlgrxzls" },
  { title: "VTE新增例数", dataIndex: "vtexzls" },
  { title: "使用呼吸机人数", dataIndex: "syhxjrs" },
  { title: "人工气道人数", dataIndex: "rgqdrs" },
  { title: "使用约束带人数", dataIndex: "syysdrs" },
  { title: "呼吸机相关性肺炎", dataIndex: "hxjxgxfy" },
];

//筛选条件
let start_time = null;
let end_time = null;
export default function MgzbTj() {
  //页面布局
  const { Header, Content } = Layout;

  //当前页数据
  const [das, setdas] = useState([{}]);

  //导出excel数据
  const downloadexcel = async () => {
    exportToExcel(columns, das, "敏感指标数据统计");
  };
  //获取当页数据
  const getdtats = () => {
    getDatas({
      currentPage: -1, //页码
      pageSize: 10, //尺寸
      start_time: start_time, //开始时间
      end_time: end_time, //结束时间
    });
  };
  //发送请求-查询数据
  const getDatas = async (props) => {
    await request.post("/hulibu/mgzb_data", props).then((res) => {
      // 按 dept 属性分组
      const groupedData = res.data.result.reduce((acc, curr) => {
        const dept = curr.dept;
        if (!acc[dept]) {
          acc[dept] = [];
        }
        acc[dept].push(curr);
        return acc;
      }, {});

      // 对每个分组下的每个字段求和，排除 regdate
      let summedData = Object.keys(groupedData).map((dept) => {
        const group = groupedData[dept];
        const summedGroup = group.reduce((acc, curr) => {
          Object.keys(curr).forEach((key) => {
            if (
              key !== "regdate" &&
              key !== "submit_time" &&
              key !== "dept" &&
              key !== "hlzlkzmgzbsbb_id"
            ) {
              acc[key] = (acc[key] || 0) + parseFloat(curr[key]);
            } else {
              acc[key] = curr[key]; // 保留非数值属性
            }
          });
          return acc;
        }, {});
        summedGroup.dept = dept;
        return summedGroup;
      });

      // 计算非计划性拔管人数与留置导管人数，以解决数据库中保存的数据为null的问题
      summedData = summedData.map((val) => {
        // 非计划性拔管人数
        val.fjhxbgrs =
          (val.wg ? val.wg : 0) +
          (val.qgcg ? val.qgcg : 0) +
          (val.zxjmdg ? val.zxjmdg : 0) +
          (val.ylg ? val.ylg : 0) +
          (val.ng ? val.ng : 0);
        // 留置导管人数
        val.lzdgrs =
          (val.lzwcg ? val.lzwcg : 0) +
          (val.lzqgcg ? val.lzqgcg : 0) +
          (val.lzzxjmdg ? val.lzzxjmdg : 0) +
          (val.lzylg ? val.lzylg : 0) +
          (val.lzng ? val.lzng : 0);
        return val;
      });

      // 计算总合计
      const totalSum = summedData.reduce(
        (acc, curr) => {
          Object.keys(curr).forEach((key) => {
            if (
              key !== "dept" &&
              key !== "regdate" &&
              key !== "submit_time" &&
              key !== "hlzlkzmgzbsbb_id"
            ) {
              acc[key] = (acc[key] || 0) + parseFloat(curr[key]);
            }
          });
          return acc;
        },
        { dept: "合计" }
      );

      summedData.push(totalSum);
      setdas(summedData);
    });
  };

  //设置时间范围
  const SelectTime = (date, dateString) => {
    start_time = dateString[0];
    end_time = dateString[1];
  };

  return (
    <>
      <Layout className="components-layout-demo">
        <Header>
          <br></br>
          &nbsp;&nbsp;&nbsp;&nbsp;上报日期：
          <DatePicker
            type="dateRange"
            density="compact"
            style={{ width: 260 }}
            onChange={SelectTime}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <MyButton name="查询" clicfun={getdtats} />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <MyButton name="导出 EXCEL" clicfun={downloadexcel} />
        </Header>

        <Content>
          <br></br>
          <Table
            columns={columns}
            dataSource={das}
            pagination={false}
            bordered={true}
            size={"middle"}
            sticky={true}
          />
        </Content>
      </Layout>
    </>
  );
}
