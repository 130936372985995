import { React, useState } from "react";
import "../css/AppIndex.modules.css";
import YuewenAppMain from "./yuewen_app_main";
import YuewenAppFile from "./yuewen_app_file";

export default function YuewenAppIndex() {
  const [activeTab, setActiveTab] = useState("tab1");

  return (
    <div className="tabs-container">
      <div className="tabs-header">
        <button
          className={activeTab === "tab1" ? "tab active" : "tab"}
          onClick={() => setActiveTab("tab1")}
        >
          <strong>阅文</strong>
        </button>
        <button
          className={activeTab === "tab2" ? "tab active" : "tab"}
          onClick={() => setActiveTab("tab2")}
        >
          <strong>文件搜索</strong>
        </button>
        <div
          className="underline"
          style={{
            transform: `translateX(${activeTab === "tab1" ? 0 : 100}%)`,
          }}
        ></div>
      </div>
      <div className="tabs-content">
        {activeTab === "tab1" && <div><YuewenAppMain/></div>}
        {activeTab === "tab2" && <div><YuewenAppFile/></div>}
      </div>
    </div>
  );
}
