import { useEffect, useState } from "react";
import { Button, Avatar, Card, Toast } from "@douyinfe/semi-ui";
import { IconArrowLeft, IconArrowRight } from "@douyinfe/semi-icons";
import requests from "../../../../utils/request_noloading";
import doWithAuthCode from "../../../../utils/dd_api";
import { get_data } from "../../../../utils/data_ctl";
import QRCodeLogin2 from "../pages/QRCodeLogin2";
import { change_route } from "../../../../router";
import { get_file_url_sync } from "../../../../utils/file";
import DingLogin from "../pages/DingLogin";

let access_token = "";

// 单点登录页面
export default function UniLogin() {
  // 是否在钉钉中打开的系统
  const inDing = get_data("inDing");
  // 是否登录成功
  const [login_success, set_login_success] = useState(false);
  // 可登录的系统
  const [clients, set_clients] = useState([]);
  // 登录成功后的操作
  const handle_login_success = () => {
    // 获取当前URL的查询字符串
    const urlParams = new URLSearchParams(window.location.search);
    // 检查是否存在要替换的参数
    if (urlParams.has("access_token")) {
      // 替换参数值
      urlParams.set("access_token", access_token);
    } else {
      // 如果参数不存在，则添加它
      urlParams.append("access_token", access_token);
    }
    if (urlParams.has("login_sys")) {
      const sys = urlParams.get("login_sys");
      if (sys === "local") {
        change_route(`/login?${urlParams.toString()}`);
      }
    }
    set_login_success(true);
  };

  // 处理扫码登录
  const handleSubmit = (code) => {
    access_token = code;
    handle_login_success();
  };

  // 处理钉钉登录
  const handleSubmit2 = async (loading, setLoading, setFailObj) => {
    if (!loading) {
      setLoading(true);
      doWithAuthCode(
        (code) => {
          access_token = code;
          handle_login_success();
        },
        (err) => {
          setLoading(false);
          setFailObj({ text: err.message, failed: true });
        }
      );
    }
  };

  const get_clients = () => {
    requests.get("/auth/get_tp_clients").then((res) => {
      set_clients(res.data);
    });
  };

  // 用户选择要登录的系统后进行登录操作
  const do_login = (redirect_url) => {
    if (
      redirect_url === null ||
      redirect_url === undefined ||
      redirect_url === ""
    ) {
      Toast.warning("该系统没有完成对接，暂时无法登录");
      return;
    }
    // 获取当前URL的查询字符串
    const urlParams = new URLSearchParams(window.location.search);
    // 检查是否存在要替换的参数
    if (urlParams.has("access_token")) {
      // 替换参数值
      urlParams.set("access_token", access_token);
    } else {
      // 如果参数不存在，则添加它
      urlParams.append("access_token", access_token);
    }
    window.location.href = `${redirect_url}?${urlParams.toString()}`;
  };

  useEffect(() => {
    get_clients();
  }, []);
  return (
    <>
      <div>
        <Card
          style={{
            width: "100%",
            height: "100vh",
            // marginLeft: "5%",
            backdropFilter: "blur(10px)",
            background: "rgba(255, 255, 255, 0.6)",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          {!login_success ? (
            <>
              {inDing ? (
                <Ding handleSubmit={handleSubmit2} />
              ) : (
                <QR handleSubmit={handleSubmit} />
              )}
            </>
          ) : (
            <ChoseSystem
              set_login_success={set_login_success}
              clients={clients}
              do_login={do_login}
            />
          )}
        </Card>
      </div>
    </>
  );
}

function QR({ handleSubmit }) {
  return (
    <div style={{ textAlign: "center" }}>
      <br />
      <br />
      <br />
      <br />
      <h2>中医伤科统一登录平台</h2>
      <QRCodeLogin2 handleSubmit={handleSubmit} />
    </div>
  );
}

function Ding({ handleSubmit }) {
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <div style={{marginTop: '70%'}}>
          <h2>中医伤科统一登录平台</h2>
          <DingLogin handleSubmit={handleSubmit} />
        </div>
      </div>
    </>
  );
}

function ChoseSystem({ set_login_success, clients, do_login }) {
  const { Meta } = Card;
  return (
    <>
      <Button
        onClick={() => set_login_success(false)}
        icon={<IconArrowLeft />}
        style={{ width: 50 }}
        aria-label="返回"
      />
      <br />
      <br />
      <div style={{ textAlign: "center" }}>
        <h2>请选择要登录的系统</h2>
      </div>
      <div>
        {clients.map((c) => {
          return (
            <>
              <Card
                bodyStyle={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                shadows="hover"
                key={c.id}
                onClick={() => do_login(c.redirect_url)}
              >
                <Meta
                  title={c.sys_name}
                  avatar={
                    <Avatar
                      alt="system logo"
                      size="default"
                      shape="square"
                      src={get_file_url_sync({ path: c.logo_url })}
                    />
                  }
                />
                <IconArrowRight
                  style={{ color: "var(--semi-color-primary)" }}
                />
              </Card>
              <br />
            </>
          );
        })}
      </div>
    </>
  );
}
