import React, { useEffect, useRef, useState } from "react";
import { Modal, Form, Toast, Avatar } from "@douyinfe/semi-ui";
import { IconPlus } from "@douyinfe/semi-icons";
import { IconUpload } from "@douyinfe/semi-icons-lab";
import requests from "../../../../utils/request";
import { get_file_url_sync } from "../../../../utils/file";

let form_data = {};

export default function UpdateTpClient({ visible, setVisible, fetchData, rawData }) {
  const [url, set_url] = useState(rawData.logo_url ? rawData.logo_url : null);
  const handleOk = () => {
    form_data.tp_client_id = rawData.tp_client_id
    form_data.logo_url = url;
    form_data.logo_url2 = undefined;
    console.log(form_data)
    requests.post("/skyy/admin/update_tp_client_info_by_id", form_data).then((res) => {
      Toast.success("新增成功");
      fetchData();
      setVisible(false);
    });
  };

  const handleAfterClose = () => {
    // fetchData()
  };

  const customRequest = ({ file, onSuccess }) => {

    const formData = new FormData();
    formData.append('file', file.fileInstance);
    formData.append('type', 'tp_client_logo')

    requests.post("/file/upload", formData).then(data => {
        onSuccess(data)
    })
}
  const onSuccess = (response, file) => {
    Toast.success("上传成功");
    set_url(response.data.path);
  };

  const style = {
    backgroundColor: "var(--semi-color-overlay-bg)",
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "var(--semi-color-white)",
  };

  const hoverMask = (
    <div style={style}>
      <IconUpload />
    </div>
  );

  let imageOnly = "image/*";
  const { Input, TextArea, Upload } = Form;

  useEffect(() => {
    console.log(rawData.logo_url)
    set_url(rawData.logo_url)
  }, [rawData])
  return (
    <>
      <Modal
        title={`client更新`}
        visible={visible}
        onOk={handleOk}
        afterClose={handleAfterClose} //>=1.16.0
        onCancel={() => setVisible(false)}
        okText={"修改"}
        cancelText={"取消"}
        closable={false}
        width={800}
        preventScroll={true}
      >
        <Form
          style={{ padding: 10, width: "100%" }}
          onValueChange={(v) => (form_data = v)}
          labelPosition="top"
          initValues={rawData}
        >
          <Input
            field="client_id"
            label="client_id"
            trigger="blur"
            autoComplete={"false"}
          />
          <Input
            field="sys_name"
            label="系统名称"
            trigger="blur"
            autoComplete={"false"}
          />
          <Input
            field="redirect_url"
            label="重定向url"
            trigger="blur"
            autoComplete={"false"}
          />
          <TextArea field="bz" label="备注" autoComplete={"false"} />
          <Upload
            className="avatar-upload"
            action=""
            onSuccess={onSuccess}
            accept={imageOnly}
            showUploadList={false}
            field="logo_url2"
            label="上传图标"
            customRequest={customRequest}
            onError={() => Toast.error("上传失败")}
          >
            <Avatar
              shape="square"
              src={url ? get_file_url_sync({path: url}) : ""}
              style={{ margin: 4 }}
              hoverMask={hoverMask}
            />
          </Upload>
        </Form>
      </Modal>
    </>
  );
}
