import { Toast } from "@douyinfe/semi-ui";
import requests from "./request_noloading";
import { get_data } from "./data_ctl";

export function download_file({ path, name }) {
  // 解决钉钉内无法下载文件的问题，会使用手机默认浏览器下载
  window.open(get_file_url_sync({path}))
  return
  requests
    .get("/file/download", {
      params: { path },
      responseType: "blob",
    })
    .then((res) => {
      if (res.data.type === "application/json") {
        const reader = new FileReader(); //创建一个FileReader实例
        reader.readAsText(res.data, "utf-8"); //读取文件,结果用字符串形式表示
        reader.onload = function () {
          //读取完成后,**获取reader.result**
          const { msg } = JSON.parse(reader.result);
          Toast.error(msg); //弹出错误提示
        };
      }
      else if (res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name); // 设置文件下载的文件名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } 
    });
}

const net_type = get_data("net_type")
const base_url = net_type === "WAN" ? process.env.REACT_APP_SERVER_URL : process.env.REACT_APP_LAN_SERVER_URL

export async function get_file_url({ path }) {
  return base_url + "/file/download?path=" + encodeURIComponent(path);
}

export function get_file_url_sync({ path }) {
  return base_url + "/file/download?path=" + encodeURIComponent(path);
}

export function del_file(path, file_id, cb = () => {}) {
  requests
    .get("/file/del_file", {
      params: {
        path,
        file_id,
      },
    })
    .then((res) => {
      cb(res);
    });
}
