import React, { useState } from 'react';
import { Upload, Button, Toast } from '@douyinfe/semi-ui';
import { IconUpload } from '@douyinfe/semi-icons';
import requests from '../../../../utils/request'

export default function FUpload({ type, urls, desc }) {

    const customRequest = ({ file, onSuccess }) => {

        const formData = new FormData();
        formData.append('file', file.fileInstance);
        formData.append('type', type)

        requests.post("/file/upload", formData).then(data => {
            onSuccess(data)
        })
    }
    const onChange = ({ fileList, currentFile }) => {
        urls.length = 0
        fileList.filter(val => val.status === "success").forEach(val => {
            urls.push({ path: val.response.data.path, name: val.name })
        })
    }

    const onSuccess = () => {
        Toast.success({ content: "上传成功", duration: 1 })
    }
    return <>
        <Upload action='' customRequest={customRequest} multiple onChange={onChange} onSuccess={onSuccess}>
            <Button icon={<IconUpload />} theme="light">
                {desc ? desc : "点击上传"}
            </Button>
        </Upload>
        <br />
    </>
}

export function ExamPoolUpload({ onSuccess = () => Toast.success({ content: "上传成功", duration: 1 }), url = "" }) {

    const customRequest = ({ file, onSuccess }) => {

        const formData = new FormData();
        formData.append('file', file.fileInstance);

        requests.post(url, formData).then(data => {
            onSuccess(data)
        })
    };
    return <>
        <Upload action='' customRequest={customRequest} multiple onSuccess={onSuccess} showUploadList={false}>
            <Button theme="light">
                导入题库
            </Button>
        </Upload>
        <br />
    </>
}

// 通用单文件上传
export function CommonSingleUpload({ onSuccess = () => Toast.success({ content: "上传成功", duration: 1 }), url = "", btn_text = "点击上传" }) {

    const customRequest = ({ file, onSuccess }) => {

        const formData = new FormData();
        formData.append('file', file.fileInstance);

        requests.post(url, formData).then(data => {
            onSuccess(data)
        })
    };
    return <>
        <Upload
            style={{ display: 'inline', marginLeft: '10px' }}
            name='file'
            customRequest={customRequest}
            onSuccess={onSuccess}
            showUploadList={false}
            action=''
        >

            <Button theme="solid">
                {btn_text}
            </Button>
        </Upload>
    </>
}
