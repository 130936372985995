import { Button } from "@douyinfe/semi-ui";
import { IconAlertTriangle } from "@douyinfe/semi-icons";
import { closeWindow } from "../../../../../utils/dd_api";

export default function ShouwenYueshiChehui() {

  const onClose = () => {
    closeWindow()
  }
  return (
    <>
      <div style={{textAlign: 'center', marginTop: '30%'}}>
        <IconAlertTriangle style={{fontSize: 50, color: "var(--semi-color-warning)"}} />
        <h2>收文被撤回，无法查看</h2>
        <Button onClick={onClose}>关闭</Button>
      </div>
    </>
  );
}
