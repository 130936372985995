import { Button, Table, Toast, Modal } from "@douyinfe/semi-ui";
import { useEffect, useState } from "react";
import { IconDownload, IconEyeOpened, IconDelete } from "@douyinfe/semi-icons";
import requests from "../../../../utils/request";
import { download_file, del_file, get_file_url } from "../../../../utils/file";
import AppendUpload from "./append_upload";
import { generateRandomString } from "../../../../utils/user_friendly"; 
import DelButton from "../../../../component/del_button";
import { get_data } from "../../../../utils/data_ctl";

export default function FileListWithAppend({
  item_id,
  type,
  show_download = true,
  show_preview = true,
  show_del = false,
}) {
  const [tb_data, setTbData] = useState([]);
  const columns = [
    // {
    //     title: 'id',
    //     dataIndex: 'file_id',
    // },
    {
      title: "文件名称",
      dataIndex: "name",
    },
    {
      title: "操作",
      dataIndex: "operate",
      render: (text, record) => (
        <Operation
          text={text}
          record={record}
          dataSource={tb_data}
          setData={setTbData}
          show_download={show_download}
          show_preview={show_preview}
          show_del={show_del}
        />
      ),
    },
  ];

  useEffect(() => {
    requests
      .get("/file/file_list", {
        params: {
          item_id,
          type,
        },
      })
      .then((res) => {
        setTbData(res.data);
      });
  }, [item_id, type]);

  const onSuccess = () => {
    Toast.success("上传成功");
    requests
      .get("/file/file_list", {
        params: {
          item_id,
          type,
        },
      })
      .then((res) => {
        setTbData(res.data);
      });
  };
  return (
    <>
      <AppendUpload type={type} item_id={item_id} onSuccess={onSuccess} />
      <br />
      <Table
        columns={columns}
        dataSource={tb_data}
        rowKey="file_id"
        pagination={false}
        bordered={true}
        style={{ lineHeight: "40px" }}
      />
    </>
  );
}

const net_type = get_data("net_type")
const preview_host = net_type === "WAN" ? process.env.REACT_APP_FILE_PREVIEW_URL : process.env.REACT_APP_LAN_FILE_PREVIEW_URL

function Operation({
  text,
  record,
  dataSource,
  setData,
  show_download,
  show_preview,
  show_del,
}) {
  const removeRecord = (key) => {
    let newDataSource = [...dataSource];
    if (key != null) {
      let idx = newDataSource.findIndex((data) => data.file_id === key);
      if (idx > -1) {
        newDataSource.splice(idx, 1);
        setData(newDataSource);
      }
    }
  };
  const handleDel = () => {
    del_file(record.path, record.file_id, (res) => {
      Toast.success("删除成功");
      removeRecord(record.file_id);
    });
  };
  const handlePreview = async () => {
    const suffix = record.name.substring(record.name.lastIndexOf("."));
    const url = (await get_file_url(record)) + `&fullfilename=${generateRandomString(10)}${suffix}`;
    window.open(
      preview_host +
        "/onlinePreview?url=" +
        btoa(encodeURIComponent(url))
    );
  };
  return (
    <>
      {show_download ? (
        <Button
          icon={<IconDownload />}
          size="small"
          theme="solid"
          style={{ marginRight: 10 }}
          onClick={() => {
            download_file(record);
          }}
        >
          下载
        </Button>
      ) : null}
      {show_preview ? (
        <Button
          icon={<IconEyeOpened />}
          size="small"
          theme="solid"
          style={{ marginRight: 10 }}
          onClick={handlePreview}
        >
          预览
        </Button>
      ) : null}

      {show_del ? (
        <DelButton onOk={handleDel} size="small"/>
      ) : null}
    </>
  );
}
