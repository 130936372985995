import { useEffect, useState } from "react";
import { Button, Spin, Typography, Layout, Toast } from "@douyinfe/semi-ui";
import { IconRefresh, IconTickCircle, IconPhone } from "@douyinfe/semi-icons";
import requests from "../../../../utils/request_noloading";
import { convertUrlParamsToJson } from "../../../../utils/location_util";
import { get_data, set_data, remove_data } from "../../../../utils/data_ctl";
import { change_route } from "../../../../router";
import { to_login } from "../../../../utils/location_util";

// 登录成功后的默认跳转路径
const default_url = "/";
// 保存用户登录信息的cookie过期时间3小时
const user_login_exp = 1000 * 60 * 60 * 3
// const user_login_exp = 1000 * 60 * 2

// 对接单点登录
export default function Login2() {
  useEffect(() => {
    remove_data("user")
    remove_data("token")
    remove_data("permission_paths", true)
    let redirect_url = convertUrlParamsToJson(window.location.search)["redirect"];
    let access_token = convertUrlParamsToJson(window.location.search)["access_token"];
    redirect_url = redirect_url ? decodeURIComponent(redirect_url)  : default_url;
    const do_login = () => {
      requests
        .get("/auth/uni_login", {
          params: { access_token },
        })
        .then((res) => {
          if (res.code === -2) {
            change_route(`/auth/uni_login${window.location.search}`);
            return;
          }
          set_data("token", res.data.token, false, user_login_exp);
          change_route(redirect_url, true);
        });
    };
    if (access_token) {
      do_login();
    } else {
      change_route(`/auth/uni_login${window.location.search}`);
    }
  }, []);

  return (
    <>
      <Spin size="large" tip="加载数据...">
        <div style={{ height: "100vh", width: "100%" }}></div>
      </Spin>
    </>
  );
}
