import React, { useState, useEffect } from "react";
import { Table, Button, Toast, Avatar } from "@douyinfe/semi-ui";
import requests from "../../../../utils/request";
import DelButton from "../../../../component/del_button";
import AddTpClient from "../pages/add_tp_client";
import { get_file_url_sync } from "../../../../utils/file";
import UpdateTpClient from "../pages/update_tp_client";

let getdas = null;
let setDas = null;
let setCurData = null;
let setClientUpdateV = null;

const operation = (text, record) => {
  const handleDel = () => {
    requests
      .get("/skyy/admin/del_tp_client", {
        params: { tp_client_id: record.tp_client_id },
      })
      .then((res) => {
        Toast.success("删除成功");
        let key = record.tp_client_id;
        const das = getdas();
        let newDataSource = [...das];
        if (key != null) {
          let idx = newDataSource.findIndex(
            (data) => data.tp_client_id === key
          );
          if (idx > -1) {
            newDataSource.splice(idx, 1);
            setDas(newDataSource);
          }
        }
      });
  };

  const handle_edit = () => {
    setCurData(record)
    setClientUpdateV(true)
  }
  return (
    <>
      <Button onClick={handle_edit} style={{ marginRight: 8 }}>编辑</Button>
      <DelButton onOk={handleDel} desc="删除" />
    </>
  );
};

const columns = [
  {
    title: "id",
    dataIndex: "tp_client_id",
  },
  {
    title: "logo",
    dataIndex: "logo_url",
    render: (text, record) => {
      return (
        <div style={{textAlign: 'center'}}>
          <Avatar
            shape="square"
            src={text ? get_file_url_sync({ path: text }) : ""}
          />
        </div>
      );
    },
  },
  {
    title: "client_id",
    dataIndex: "client_id",
  },
  {
    title: "系统名称",
    dataIndex: "sys_name",
  },
  {
    title: "重定向url",
    dataIndex: "redirect_url",
  },
  {
    title: "备注",
    dataIndex: "bz",
  },
  {
    title: "操作",
    render: operation,
  },
];

export default function TpClientManage() {
  const [dataSource, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [clientAddVisible, setClientAddVisible] = useState(false);
  const [clientUpdateVisible, setClientUpdateVisible] = useState(false);
  const [cur_data, set_cur_data] = useState({});

  setCurData = set_cur_data;

  setClientUpdateV = setClientUpdateVisible;

  setDas = setData;

  getdas = () => dataSource;

  const fetchData = (cur = currentPage, size = pageSize) => {
    setLoading(true);
    requests
      .post("/skyy/admin/get_tp_clients", { currentPage: cur, pageSize: size })
      .then((res) => {
        setLoading(false);
        setData(res.data.result);
        setTotal(res.data.total);
      });
  };

  const handleChange = (cur, size) => {
    setPage(cur);
    setPageSize(size);
  };

  useEffect(() => {
    fetchData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  return (
    <>
      <div>
        <Button onClick={() => setClientAddVisible(true)}>新增client</Button>
      </div>
      <Table
        columns={columns}
        rowKey={"tp_client_id"}
        dataSource={dataSource}
        pagination={{
          currentPage,
          pageSize: pageSize,
          total: total,
          onChange: handleChange,
          showSizeChanger: true,
          pageSizeOpts: [10, 20, 30, 40, 50, 100, 500],
        }}
        loading={loading}
        bordered={true}
        sticky={true}
      />
      <AddTpClient
        visible={clientAddVisible}
        setVisible={setClientAddVisible}
        fetchData={fetchData}
      />
      <UpdateTpClient
      visible={clientUpdateVisible}
      setVisible={setClientUpdateVisible}
      fetchData={fetchData}
      rawData={cur_data}
      />
    </>
  );
}
