import { useRouteError } from "react-router-dom";
import { Typography } from "@douyinfe/semi-ui";
import { to_login } from "./utils/location_util";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);
  const {Text} = Typography

  return (
    <div id="error-page" style={{textAlign: 'center'}}>
      <h1>{error.statusText || error.message}</h1>
      {/* <Text link underline onClick={() => {
        to_login(false)
      }} style={{fontSize: "20px"}}>点击返回登录页</Text> */}
    </div>
  );
}