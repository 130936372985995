import React, {useCallback } from 'react';
import { ImagePreview} from '@douyinfe/semi-ui';

export default function ImgsShow({visible,setvisible,srcList}){
    const visibleChange = useCallback((v) => {
        setvisible(v);
    }, []);
    return ( 
        <>
            <ImagePreview
                src={srcList}
                visible={visible}
                onVisibleChange={visibleChange}
            />
        </>
    );
};
