import { useEffect, useState } from "react";
import UserList from "../pages/user_list";
import requests from "../../../../utils/request";
import EditUser from "../pages/user_edit";
import UserMsgEditor from "../pages/user_msg_editor"
import { Button, Input, Modal, Toast, Space, Tag } from "@douyinfe/semi-ui";
import AddUser from "../pages/add_user";
import { copyToClip } from "../../../../utils/user_friendly";


const color_list = ['amber', 'blue', 'cyan', 'green', 'grey', 'indigo',  
    'light-blue', 'light-green', 'lime', 'orange', 'pink',  
    'purple', 'red', 'teal', 'violet', 'yellow', 'white'
]

// 工号导入失败用户展示
function FailedTag({ failed }) {
  const color_dict = {};
  let i = 0
  const f_count = color_list.length
  const get_color = (name) => {
    if (color_dict[name]) {
      return color_dict[name];
    } else {
      const color = color_list[i];
      color_dict[name] = color;
      if(i >= f_count - 1) {
        i = 0;
      } else {
        i++;
      }
      console.log(i)
      return color;
    }
  };

  return (
    <>
      <strong>以下用户未匹配到工号或存在重名:</strong>
      <br />
      <Space wrap>
        {failed.map((f) => (
          <Tag
            shape="circle"
            color={get_color(f.split(":")[0])}
            key={f}
            onClick={() => {
                copyToClip(f)
                Toast.success("复制成功")
            }}
          >
            {f}
          </Tag>
        ))}
      </Space>
    </>
  );
}

export default function UserManage() {
  const [user_data, setUserData] = useState([]);
  const [edit_user_visible, setEditUserVisible] = useState(false);
  const [add_user_visible, setAddUserVisible] = useState(false);
  const [edit_usermsg_visible, setEditUsermsgVisible] = useState(false);
  const [edit_user_data, setEditUserData] = useState({});
  const [edit_userMsg_data, setEditUserMsgData] = useState({});
  const [user_group_list, setUserGroupList] = useState([]);
  const [selected_group_list, setSelectedGroupList] = useState([]);
  const [currentPage, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [fetchFlag, setFetchFlag] = useState(false);
  const [search_name, setSearchName] = useState("");
  const [search_dept, setSearchDept] = useState("");
  const fetch_data = (
    q = { dept: search_dept, username: search_name },
    cur = currentPage,
    size = pageSize
  ) => {
    requests
      .post("/skyy/admin/get_user_list", {
        ...q,
        currentPage: cur,
        pageSize: size,
      })
      .then((res) => {
        setUserData(res.data.result);
        setTotal(res.data.total);
      });
  };
  const get_user_group_list = () =>
    requests.get("/skyy/admin/get_user_group_list");
  const get_user_group_list_by_id = (user_id) =>
    requests.get("/skyy/admin/get_user_group_by_id", {
      params: {
        user_id,
      },
    });
  const update_user_group = (ids, user_id) =>
    requests.post("/skyy/admin/update_user_group_by_userid", { ids, user_id });

  useEffect(() => {
    fetch_data();
  }, [currentPage, pageSize, fetchFlag,edit_usermsg_visible]);

  const handle_search = () => {
    setPage(1);
    setFetchFlag(!fetchFlag);
  };

  const handleChange = (cur, size) => {
    setPage(cur);
    setPageSize(size);
  };

  const handleUpdateDept = () => {
    requests.post("/skyy/admin/update_user_dept").then((res) => {
      Toast.info("更新成功");
      fetch_data();
    });
  };

  const handleUpdateGonghao = () => {
    requests.get("/skyy/admin/update_gonghao_data").then((res) => {
      Modal.info({
        title: "更新完成",
        content: <FailedTag failed={res.data.failed} />,
        hasCancel: false,
      });
      fetch_data();
    });
  };

  return (
    <>
      {/* 搜索框 */}
      <div style={{ float: "left", marginBottom: "10px" }}>
        <Input
          showClear
          prefix="用户名:"
          style={{ width: "220px" }}
          onChange={(val) => (setSearchName(val))}
        ></Input>
        <Input
          showClear
          prefix="部门:"
          style={{ width: "200px", marginLeft: "10px" }}
          onChange={(val) => (setSearchDept(val))}
        ></Input>
        <Button
          theme="solid"
          style={{ marginLeft: "10px" }}
          onClick={handle_search}
        >
          搜索
        </Button>
      </div>
      <Button
        theme="solid"
        onClick={handleUpdateDept}
        style={{ marginBottom: "10px", float: "right", marginLeft: "10px" }}
      >
        更新用户部门
      </Button>
      <Button
        theme="solid"
        onClick={handleUpdateGonghao}
        style={{ marginBottom: "10px", float: "right", marginLeft: "10px" }}
      >
        更新用户工号
      </Button>
      <Button
        theme="solid"
        onClick={() => setAddUserVisible(true)}
        style={{ marginBottom: "10px", float: "right" }}
      >
        添加用户
      </Button>
      <UserList
        tb_data={user_data}
        set_edit_user_visible={setEditUserVisible}
        set_edit_userMsg_visible={setEditUsermsgVisible}
        set_user_data={setEditUserData}
        setEditUserMsgData={setEditUserMsgData}
        get_user_group_list={get_user_group_list}
        get_user_group_list_by_id={get_user_group_list_by_id}
        setUserGroupList={setUserGroupList}
        setSelectedGroupList={setSelectedGroupList}
        currentPage={currentPage}
        pageSize={pageSize}
        total={total}
        handleChange={handleChange}
      />
      <EditUser
        visible={edit_user_visible}
        set_visible={setEditUserVisible}
        user_data={edit_user_data}
        user_group_list={user_group_list}
        selected_group_list={selected_group_list}
        update_user_group={update_user_group}
        setSelectedGroupList={setSelectedGroupList}
      />
      <UserMsgEditor
        visible={edit_usermsg_visible}
        setVisible={setEditUsermsgVisible}
        value={edit_userMsg_data}
      />
      <AddUser
        visible={add_user_visible}
        setVisible={setAddUserVisible}
        fetch_d={fetch_data}
      />
    </>
  );
}
