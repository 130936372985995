import React, { useEffect, useRef, useState } from "react";
import { Modal, Form, Toast } from "@douyinfe/semi-ui";
import requests from "../../../../utils/request_noloading";

let form_data = null;

export default function EditXxkAccount({
  cur_data,
  visible,
  setVisible,
  fetchData,
}) {
  const [confirm_l, set_confirm_l] = useState(false);
  if (cur_data) {
    cur_data.create_time = undefined;
    cur_data.update_time = undefined;
  }

  const handleOk = () => {
    set_confirm_l(true);
    requests
      .post("/xxk/account/update_account", form_data)
      .then((res) => {
        Toast.success("修改成功");
        fetchData();
        setVisible(false);
      })
      .finally(() => set_confirm_l(false));
  };

  const handleAfterClose = () => {
    // fetchData()
  };
  const { Input, TextArea } = Form;

  return (
    <>
      <Modal
        title={`编辑资源账号`}
        visible={visible}
        onOk={handleOk}
        afterClose={handleAfterClose} //>=1.16.0
        onCancel={() => setVisible(false)}
        okText={"修改"}
        cancelText={"取消"}
        closable={false}
        footerFill={true}
        confirmLoading={confirm_l}
        fullScreen
      >
        <Form
          style={{ width: "100%" }}
          onValueChange={(v) => (form_data = v)}
          initValues={cur_data}
        >
          <Input
            field="name"
            label="资源名称"
            trigger="blur"
            autoComplete={"false"}
          />
          <Input
            allowEmptyString
            field="ip"
            label="IP地址"
            trigger="blur"
            autoComplete={"false"}
          />
          <TextArea
            allowEmptyString
            field="content"
            label="账号内容"
            trigger="blur"
            autoComplete={"false"}
            autosize={{ minRows: 5, maxRows: 20 }}
          />
          <TextArea
            allowEmptyString
            field="bz"
            label="备注"
            trigger="blur"
            autoComplete={"false"}
            autosize={{ minRows: 5, maxRows: 20 }}
          />
        </Form>
      </Modal>
    </>
  );
}
